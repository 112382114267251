<template>
  <div id="leftMenu">
    <ul id="leftMenuItem">
      <li class="menu-wrapper">
        <h3>
          <router-link to="/home" active-class="active">음원관리</router-link>
        </h3>
        <ul>
          <li>
            <router-link to="/home/musicupload" exact active-class="active"
              >음원입력</router-link
            >
          </li>
          <li>
            <router-link to="/home" exact active-class="active"
              >음원목록</router-link
            >
          </li>
        </ul>
      </li>
      <li class="menu-wrapper">
        <h3>
          <router-link to="/home/teacheradd" exact active-class="active"
            >강사관리</router-link
          >
        </h3>
        <ul>
          <li>
            <router-link to="/home/teacheradd" exact active-class="active"
              >강사등록</router-link
            >
          </li>
          <li>
            <router-link to="/home/teacherlist" exact active-class="active"
              >강사목록</router-link
            >
          </li>
          <li>
            <router-link to="/home/branchadd" exact active-class="active"
              >지사등록</router-link
            >
          </li>
        </ul>
      </li>
      <li class="menu-wrapper">
        <h3>
          <router-link to="/home/categoryadddelete" exact active-class="active"
            >과목관리</router-link
          >
        </h3>
        <ul>
          <li>
            <router-link
              to="/home/categoryadddelete"
              exact
              active-class="active"
              >과목 추가/삭제</router-link
            >
          </li>
          <li>
            <router-link to="/home/classadddelete" exact active-class="active"
              >과목 분류 추가/삭제</router-link
            >
          </li>
        </ul>
      </li>
      <!-- <li class="menu-wrapper">
        <h3>
          <router-link to="/home/playlistadd" exact active-class="active"
            >추천 플레이리스트 관리</router-link
          >
        </h3>
        <ul>
          <li>
            <router-link to="/home/playlistadd" exact active-class="active"
              >추천 플레이리스트 추가</router-link
            >
          </li>
          <li>
            <router-link to="/home/playlistmanage" exact active-class="active"
              >추천 플레이리스트 관리</router-link
            >
          </li>
        </ul>
      </li> -->
      <li class="menu-wrapper">
        <h3>
          <router-link to="/home/banneradd" exact active-class="active"
            >배너관리</router-link
          >
        </h3>
        <ul>
          <li>
            <router-link to="/home/banneradd" exact active-class="active"
              >배너 추가</router-link
            >
          </li>
          <li>
            <router-link to="/home/bannermanage" exact active-class="active"
              >배너 관리</router-link
            >
          </li>
        </ul>
      </li>
      <li class="menu-wrapper">
        <h3>
          <router-link to="/home/splashimage" exact active-class="active"
            >앱 시작화면 관리</router-link
          >
        </h3>
        <ul>
          <li>
            <router-link to="/home/splashimage" exact active-class="active"
              >앱 시작화면 관리</router-link
            >
          </li>
          <li>
            <router-link to="/home/testmodeset" exact active-class="active"
              >테스트 모드 설정</router-link
            >
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {
    const menuIdx = $("#leftMenuItem li.menu-wrapper").index(
      $(
        "#leftMenuItem li > a[href='" + this.$router.history.current.path + "']"
      ).closest("li.menu-wrapper")
    );
    $("#leftMenuItem").accordion({
      heightStyle: "content",
      active: menuIdx,
    });
  },
  methods: {},
};
</script>
<style scoped></style>
