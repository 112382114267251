<template>
  <div class="content-box">
    <div class="location">배너관리 > 배너 추가</div>

    <div class="content-wrapper">
      <div class="tit-sub">배너 추가</div>
      <div class="input-df-row" style="margin: 10px 0">
        <input type="checkbox" v-model="isMainShowChk" />
        <span class="tit"> 메인노출 </span>
      </div>
      <br />
      <div class="input-df-row" style="margin: 10px 0">
        <span class="tit">배너명 : </span>
        <input type="text" v-model="name" />
      </div>
      <br />
      <div class="input-df-row" style="margin: 10px 0">
        <span class="tit">링크 (http를 포함한 모든 주소) : </span>
        <input
          type="text"
          v-model="link"
          placeholder=" 예) https://orff.kr/?page_id=57&uid=154&mod=document&pageid=1"
          style="width: 450px"
        />
      </div>
      <br />
      <div class="input-df-row" style="margin: 10px 0">
        <span class="tit">배너 이미지 : </span>
        <input type="file" id="attachment" name="attachment" />
      </div>
      <br />
      <button
        class="btn-blue"
        @click="add"
        style="width: 675px; margin-top: 50px"
      >
        추 가
      </button>
    </div>
  </div>
</template>
<script>
import { post } from "axios";
import apiUrl from "@/apiUrl";
import EventBus from "@/eventbus/eventbus";
import { forEach, find, groupBy } from "lodash";
export default {
  components: {},
  data() {
    return {
      isMainShowChk: false,
      name: null,
      link: null,
    };
  },
  created() {},
  mounted() {},
  methods: {
    add() {
      const vm = this;
      const formData = new FormData();
      const attachment = document.querySelector("#attachment");

      if (
        !attachment.files.length ||
        !this.name ||
        this.name == "" ||
        !this.link ||
        this.link == ""
      ) {
        EventBus.$emit("showToast", "항목을 모두 입력해 주세요.");
        return;
      }

      if (!confirm("저장하시겠습니까?")) {
        return;
      }
      formData.append("name", this.name);
      formData.append("link", this.link);
      formData.append("attachment", attachment.files[0]);
      formData.append("is_show_main", this.isMainShowChk ? "Y" : "N");

      EventBus.$emit("startLoading");
      post(apiUrl.addBanner, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          EventBus.$emit("endLoading");
          if (res.data.status == "success") {
            this.name = null;
            this.link = null;
            attachment.value = null;
            EventBus.$emit("showToast", "저장되었습니다.");
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("endLoading");
        });
    },
  },
};
</script>
<style lang="scss"></style>
