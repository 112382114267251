/*
전역으로 사용할 함수 등록
*/
import moment from "moment";
import { forEach, sortBy, find } from "lodash";
import hangul from "hangul-js";

const convertDate = (data) => {
  return moment(data).format("YYYY.MM.DD HH:mm");
};

const convertPlayTime = (milliseconds) => {
  return moment.utc(milliseconds).format("mm:ss");
};

const getCurrentList = (
  keyword,
  sortKey,
  sortAsc,
  list,
  currentPage,
  perPage
) => {
  let res = [];
  forEach(list, (o) => {
    let isAdd = false;
    forEach(o, (oo) => {
      if (("" + oo).toLowerCase().indexOf(keyword.toLowerCase()) != -1) {
        isAdd = true;
      }
      if (!isAdd) {
        const disassemble = hangul.disassemble(oo, true);
        let cho = "";
        forEach(disassemble, (ooo) => {
          cho += ooo[0];
        });
        if (cho.indexOf(keyword.toLowerCase()) != -1) {
          isAdd = true;
        }
      }
    });
    if (isAdd) {
      res.push(o);
    }
  });
  if (sortKey) {
    res = sortBy(res, sortKey);
    if (sortAsc == "desc") {
      res.reverse();
    }
  }
  let startNum = (currentPage - 1) * perPage;
  return {
    total: res.length,
    list: res.slice(startNum, Number(startNum) + Number(perPage)),
  };
};

const pageAlgo = (total, bottomSize, listSize, cursor) => {
  //total = 총 갯수
  //bottomSize = 하단크기
  //listSize = 화면에서 보여줄 크기
  //cursor = 현재 나의 페이지

  let totalPageSize = Math.ceil(total / listSize); //한 화면에 보여줄 갯수에서 구한 하단 총 갯수

  if (cursor % bottomSize == 0) {
    cursor -= 1;
  }
  let firstBottomNumber = cursor - (cursor % bottomSize) + 1; //하단 최초 숫자
  let lastBottomNumber = cursor - (cursor % bottomSize) + bottomSize; //하단 마지막 숫자

  if (lastBottomNumber > totalPageSize) lastBottomNumber = totalPageSize; //총 갯수보다 큰 경우 방지

  return {
    firstBottomNumber,
    lastBottomNumber,
    totalPageSize,
    total,
    bottomSize,
    listSize,
    cursor,
  };
};

const ctxOpenImage = (url) => {
  let w = window.open(
    "",
    "_blank",
    "width=1000, height=1000, location=no,staus=no,toolbar=no"
  );
  let image = new Image();
  image.src = url;
  $(image).css("width", "100%");
  setTimeout(function () {
    w.document.write(image.outerHTML);
  }, 0);
};

export default {
  install(Vue) {
    Vue.prototype.convertDate = convertDate;
    Vue.prototype.convertPlayTime = convertPlayTime;
    Vue.prototype.getCurrentList = getCurrentList;
    Vue.prototype.pageAlgo = pageAlgo;
    Vue.prototype.ctxOpenImage = ctxOpenImage;
  },
};
