<template>
  <div class="content-box">
    <div class="location">테스트 모드 설정</div>

    <div class="content-wrapper">
      <div class="input-df-row">
        <label class="mgl-20">
          <input type="checkbox" v-model="isChecked" />
          테스트 모드 설정
        </label>
        <button class="btn-blue" style="margin-left: 10px" @click="update">
          적 용
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { post } from "axios";
import apiUrl from "@/apiUrl";
import EventBus from "@/eventbus/eventbus";
import { forEach, find, groupBy } from "lodash";
export default {
  components: {},
  data() {
    return {
      isChecked: false,
    };
  },
  created() {},
  async mounted() {
    await this.getTestMode();
  },
  methods: {
    getTestMode() {
      EventBus.$emit("startLoading");
      post(
        apiUrl.getTestMode,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          EventBus.$emit("endLoading");
          if (res.data.status == "success") {
            this.isChecked = res.data.result;
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("endLoading");
        });
    },
    update() {
      EventBus.$emit("startLoading");
      post(
        apiUrl.changeTestMode,
        { isChecked: this.isChecked },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          EventBus.$emit("endLoading");
          if (res.data.status == "success") {
            EventBus.$emit("showToast", "수정되었습니다.");
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("endLoading");
        });
    },
  },
};
</script>
<style lang="scss"></style>
