<template>
  <div class="content-box">
    <div class="location">강사관리 > 지사등록</div>

    <div class="content-wrapper" v-if="!selectedRow">
      <div class="tit-sub">지역 추가</div>
      <div class="input-df-row">
        <span class="tit">지역명 : </span>
        <input type="text" v-model="regionName" />
      </div>
      <div class="input-df-row">
        <button class="btn-blue" @click="add">추 가</button>
      </div>
    </div>

    <div
      class="content-wrapper"
      v-if="selectedRow && selectedRow.type == 'region'"
    >
      <div class="tit-sub">지역 수정</div>
      <div class="input-df-row">
        <span class="tit">지역명 : </span>
        <input type="text" v-model="selectedRow.name" />
      </div>
      <div class="input-df-row">
        <button class="btn-blue" @click="editRegion">수 정</button>
        <button
          class="btn-white"
          @click="selectedRow = null"
          style="margin-left: 10px"
        >
          취 소
        </button>
      </div>
    </div>

    <div
      class="content-wrapper"
      v-if="selectedRow && selectedRow.type == 'branch'"
    >
      <div class="tit-sub">지사 수정</div>
      <div class="input-df-row">
        <span class="tit">지역선택 : </span>
        <select v-model="editBranchSelectedRegion">
          <option
            v-for="(item, index) in regionList"
            :key="index"
            :value="item"
          >
            {{ item.name }}
          </option>
        </select>
      </div>
      <div class="input-df-row">
        <span class="tit">지사명 : </span>
        <input type="text" v-model="selectedRow.name" />
      </div>
      <div class="input-df-row">
        <button class="btn-blue" @click="editBranch">수 정</button>
        <button
          class="btn-white"
          @click="selectedRow = null"
          style="margin-left: 10px"
        >
          취 소
        </button>
      </div>
    </div>

    <div class="list-region" v-show="!selectedRow">
      <table>
        <colgroup>
          <col width="25%" />
          <col width="*" />
        </colgroup>
        <tr v-for="(item, index) in regionObjList" :key="index">
          <th>
            {{ item.name }}
            <button
              class="btn-gray"
              style="margin-left: 10px"
              @click="rowSelect(item, 'region')"
            >
              수정
            </button>
            <button
              class="btn-gray"
              style="margin-left: 5px"
              @click="deleteRegion(item)"
            >
              삭제
            </button>
            <button
              class="btn-gray"
              style="margin-left: 5px"
              @click="addBranch(item)"
            >
              추가
            </button>
          </th>
          <td>
            <div
              class="branch-item"
              v-for="(branch, branchIndex) in item.children"
              :key="branchIndex"
            >
              {{ branch.name }}
              <button
                class="btn-gray"
                style="margin-left: 10px"
                @click="rowSelect(branch, 'branch')"
              >
                수정
              </button>
              <button
                class="btn-gray"
                style="margin-left: 5px"
                @click="deleteBranch(branch)"
              >
                삭제
              </button>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { post } from "axios";
import apiUrl from "@/apiUrl";
import EventBus from "@/eventbus/eventbus";
import { forEach, find, groupBy } from "lodash";
export default {
  components: {},
  data() {
    return {
      regionName: null,
      regionList: [],
      regionObjList: {},
      branchList: [],
      selectedRow: null,
      editBranchSelectedRegion: null,
    };
  },
  created() {},
  mounted() {
    this.getRegionList();
  },
  watch: {},
  methods: {
    rowSelect(obj, type) {
      this.selectedRow = JSON.parse(JSON.stringify(obj));
      this.selectedRow.type = type;
      if (type == "branch") {
        this.editBranchSelectedRegion = find(this.regionList, {
          id: obj.region_id,
        });
      }
    },
    getRegionList() {
      EventBus.$emit("startLoading");
      post(
        apiUrl.getRegion,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.data.status == "success") {
            const vm = this;
            vm.regionList = res.data.result;
            vm.regionObjList = {};
            forEach(this.regionList, (o, k) => {
              vm.regionObjList[o.id] = o;
              vm.regionObjList[o.id]["children"] = [];
            });
            this.getBranchList();
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBranchList() {
      post(
        apiUrl.getBranch,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          EventBus.$emit("endLoading");
          if (res.data.status == "success") {
            const vm = this;
            vm.branchList = res.data.result;
            const tempObj = JSON.parse(JSON.stringify(this.regionObjList));
            forEach(vm.branchList, (o) => {
              tempObj[o.region_id]["children"].push(o);
            });
            this.regionObjList = tempObj;
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("endLoading");
        });
    },
    add() {
      if (!this.regionName || this.regionName == "") {
        EventBus.$emit("showToast", "지역명을 입력해 주세요.");
        return;
      }
      if (!confirm("추가 하시겠습니까?")) {
        return;
      }
      EventBus.$emit("startLoading");

      post(
        apiUrl.addRegion,
        { name: this.regionName },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          EventBus.$emit("endLoading");
          if (res.data.status == "success") {
            EventBus.$emit("showToast", "추가 되었습니다.");
            this.regionName = null;
            this.getRegionList();
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("endLoading");
        });
    },
    editRegion() {
      if (
        !this.selectedRow ||
        this.selectedRow.type != "region" ||
        !this.selectedRow.name ||
        this.selectedRow.name == ""
      ) {
        EventBus.$emit("showToast", "지역명을 입력해 주세요.");
        return;
      }
      if (!confirm("수정 하시겠습니까?")) {
        return;
      }

      EventBus.$emit("startLoading");

      post(
        apiUrl.editRegion,
        { id: this.selectedRow.id, name: this.selectedRow.name },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          EventBus.$emit("endLoading");
          if (res.data.status == "success") {
            this.selectedRow = null;
            EventBus.$emit("showToast", "수정 되었습니다.");
            this.getRegionList();
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("endLoading");
        });
    },

    deleteRegion(obj) {
      if (!confirm("삭제 하시겠습니까?")) {
        return;
      }
      EventBus.$emit("startLoading");

      post(
        apiUrl.deleteRegion,
        { id: obj.id },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          EventBus.$emit("endLoading");
          if (res.data.status == "success") {
            this.selectedRow = null;
            EventBus.$emit("showToast", "삭제 되었습니다.");
            this.getRegionList();
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("endLoading");
        });
    },

    addBranch(obj) {
      const inputBranch = prompt("지사명을 입력 하세요.", "");
      console.log(inputBranch);

      if (!inputBranch || inputBranch == "") {
        EventBus.$emit("showToast", "지사명을 입력해 주세요.");
        return;
      }
      if (!confirm("추가 하시겠습니까?")) {
        return;
      }

      EventBus.$emit("startLoading");

      post(
        apiUrl.addBranch,
        { region_id: obj.id, name: inputBranch },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          EventBus.$emit("endLoading");
          if (res.data.status == "success") {
            EventBus.$emit("showToast", "추가 되었습니다.");
            this.getRegionList();
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("endLoading");
        });
    },
    editBranch() {
      if (
        !this.selectedRow ||
        this.selectedRow.type != "branch" ||
        !this.selectedRow.name ||
        this.selectedRow.name == ""
      ) {
        EventBus.$emit("showToast", "지사명을 입력해 주세요.");
        return;
      }
      if (!confirm("수정 하시겠습니까?")) {
        return;
      }

      EventBus.$emit("startLoading");

      post(
        apiUrl.editBranch,
        {
          id: this.selectedRow.id,
          region_id: this.editBranchSelectedRegion.id,
          name: this.selectedRow.name,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          EventBus.$emit("endLoading");
          if (res.data.status == "success") {
            this.selectedRow = null;
            EventBus.$emit("showToast", "수정 되었습니다.");
            this.getRegionList();
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("endLoading");
        });
    },

    deleteBranch(obj) {
      if (!confirm("삭제 하시겠습니까?")) {
        return;
      }
      EventBus.$emit("startLoading");

      post(
        apiUrl.deleteBranch,
        { id: obj.id },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          EventBus.$emit("endLoading");
          if (res.data.status == "success") {
            this.selectedRow = null;
            EventBus.$emit("showToast", "삭제 되었습니다.");
            this.getRegionList();
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("endLoading");
        });
    },
  },
};
</script>
<style lang="scss"></style>
