// store.js
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const store = new Vuex.Store({
  // 상태
  state: {},
  getters: {
    projectId: (state) => {
      return state.project.map((item) => {
        return {};
      });
    },
  },
});
