<!--
로딩 컴포넌트
-->
<template>
  <div v-if="isLoading">
    <img src="../../assets/imgs/oval.svg" width="50" alt="" />
  </div>
</template>

<script>
import EventBus from "@/eventbus/eventbus";

export default {
  name: "Spinner",
  data: function () {
    return {
      isLoading: false,
    };
  },
  created: function () {
    EventBus.$on("startLoading", this.startLoading);
    EventBus.$on("endLoading", this.endLoading);
  },
  methods: {
    startLoading: function () {
      this.isLoading = true;
    },
    endLoading: function () {
      this.isLoading = false;
    },
  },
  beforeDestroy() {
    EventBus.$off("startLoading");
    EventBus.$off("endLoading");
  },
};
</script>

<style type="text/css" scoped>
div {
  background: #a0a0a0;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  padding-top: 25%;
  opacity: 0.8;
}

b-spinner {
  margin: 0 auto;
  height: 64px;
  width: 64px;
}
</style>
