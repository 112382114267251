<template>
  <div class="content-box">
    <div class="location">음원관리 > 음원목록</div>

    <div class="content-wrapper" v-if="!selectedRow">
      <div class="input-df-row">
        <span class="tit">과목선택 : </span>
        <select v-model="seletecCategory">
          <option value="all">전체</option>
          <option
            v-for="(item, index) in categoryList"
            :key="index"
            :value="item"
          >
            {{ item.name }}
          </option>
        </select>
      </div>
      <div class="input-df-row">
        <span class="tit">분류선택 : </span>
        <select v-model="seletecClass">
          <option value="all">전체</option>
          <option
            v-for="(item, index) in selectedClassList"
            :key="index"
            :value="item"
          >
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="content-wrapper" v-if="selectedRow">
      <div class="tit-sub">음원 분류 수정</div>
      <div class="input-df-row">
        <span class="tit">과목 선택 : </span>
        <select v-model="editSeletecCategory">
          <option
            v-for="(item, index) in categoryList"
            :key="index"
            :value="item"
          >
            {{ item.name }}
          </option>
        </select>
      </div>
      <div class="input-df-row">
        <span class="tit">분류 선택 : </span>
        <select v-model="editSeletecClass">
          <option
            v-for="(item, index) in editSelectedClassList"
            :key="index"
            :value="item"
          >
            {{ item.name }}
          </option>
        </select>
      </div>

      <div class="input-df-row">
        <span class="tit">음원 제목 : </span>
        <input type="text" v-model="selectedRow.name" disabled />
      </div>

      <div class="input-df-row">
        <button class="btn-blue" @click="update">수 정</button>
        <button
          class="btn-white"
          @click="selectedRow = null"
          style="margin-left: 10px"
        >
          취 소
        </button>
      </div>
    </div>

    <div class="list-df" v-show="!selectedRow">
      <div class="table-top">
        <div class="fl">
          표시 :
          <select v-model="paging.perPage">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <div class="fr">
          검색 :
          <input
            type="text"
            @input="
              searchText = $event.target.value;
              parseList();
            "
            placeholder="검색어를 입력해 주세요."
          />
        </div>
      </div>
      <table>
        <colgroup>
          <col width="70" />
          <col width="70" />
          <col width="150" />
          <col width="150" />
          <col width="*" />
          <col width="150" />
          <col width="220" />
          <col width="100" />
        </colgroup>
        <thead>
          <tr>
            <th
              class="sorting"
              @click="sortList('num')"
              v-bind:class="{
                sorting_asc: sortKey == 'num' && sortAsc == 'asc',
                sorting_desc: sortKey == 'num' && sortAsc == 'desc',
              }"
            >
              번호
            </th>
            <th style="text-align: center">
              <input type="checkbox" v-model="selectAll" />
            </th>
            <th
              class="sorting"
              @click="sortList('category_name')"
              v-bind:class="{
                sorting_asc: sortKey == 'category_name' && sortAsc == 'asc',
                sorting_desc: sortKey == 'category_name' && sortAsc == 'desc',
              }"
            >
              과목
            </th>
            <th
              class="sorting"
              @click="sortList('class_name')"
              v-bind:class="{
                sorting_asc: sortKey == 'class_name' && sortAsc == 'asc',
                sorting_desc: sortKey == 'class_name' && sortAsc == 'desc',
              }"
            >
              분류
            </th>
            <th
              class="sorting"
              @click="sortList('name')"
              v-bind:class="{
                sorting_asc: sortKey == 'name' && sortAsc == 'asc',
                sorting_desc: sortKey == 'name' && sortAsc == 'desc',
              }"
            >
              제목
            </th>
            <th>재생시간</th>
            <th
              class="sorting"
              @click="sortList('reg_date')"
              v-bind:class="{
                sorting_asc: sortKey == 'reg_date' && sortAsc == 'asc',
                sorting_desc: sortKey == 'reg_date' && sortAsc == 'desc',
              }"
            >
              등록일
            </th>
            <th>편집</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in list"
            v-bind:key="index"
            v-bind:class="{ active: selectedRow && selectedRow.id == row.id }"
          >
            <td>{{ row.num }}</td>
            <td><input type="checkbox" v-model="row.ischecked" /></td>
            <td class="ta-left">{{ row.category_name }}</td>
            <td class="ta-left">{{ row.class_name }}</td>
            <td class="ta-left">{{ row.name }}</td>
            <td class="ta-left">{{ convertPlayTime(row.play_time) }}</td>
            <td class="ta-left">{{ convertDate(row.reg_date) }}</td>
            <td class="ta-left">
              <button class="btn-blue" @click="editClick(row)">편 집</button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination" v-if="list.length">
        <div class="btn-action">
          <button class="btn-orange" @click="deleteRow">선택삭제</button>
        </div>
        <div class="paging">
          <button
            v-bind:class="{ disabled: paging.currentPage == 1 }"
            @click="parseList(1)"
          >
            첫페이지
          </button>
          <button
            v-bind:class="{ disabled: paging.currentPage == 1 }"
            @click="parseList(paging.currentPage - 1)"
          >
            이전
          </button>
          <button
            v-for="index in paging.range"
            v-bind:key="index"
            @click="parseList(index)"
            v-bind:class="{ on: index == paging.currentPage }"
          >
            {{ index }}
          </button>
          <button
            v-bind:class="{ disabled: paging.currentPage == paging.totalPage }"
            @click="parseList(paging.currentPage + 1)"
          >
            다음
          </button>
          <button
            v-bind:class="{ disabled: paging.currentPage == paging.totalPage }"
            @click="parseList(paging.totalPage)"
          >
            끝페이지
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { post } from "axios";
import apiUrl from "@/apiUrl";
import EventBus from "@/eventbus/eventbus";
import { forEach, find, groupBy } from "lodash";
export default {
  components: {},
  data() {
    return {
      selectAll: false,
      categoryList: [],
      categoryNameList: {},
      seletecCategory: null,
      classList: [],
      selectedClassList: [],
      classNameList: {},
      seletecClass: null,
      originList: null,
      list: [],
      searchText: "",
      sortKey: "",
      sortAsc: null,
      selectedRow: null,
      editSeletecCategory: null,
      editSeletecClass: null,
      editSelectedClassList: [],
      paging: {
        currentPage: 1,
        totalCnt: 0,
        perPage: 10,
        totalPage: 0,
        pageCount: 10,
        range: [],
      },
    };
  },
  created() {},
  async mounted() {
    await this.getCategoryList();
    this.getClassList();
  },
  watch: {
    selectAll(val) {
      forEach(this.list, (o) => {
        o["ischecked"] = val;
      });
    },
    seletecCategory(val) {
      if (this.seletecCategory) {
        this.seletecClass = null;
        if (val == "all") {
          this.selectedClassList = [];
        } else {
          this.selectedClassList = this.classNameList[this.seletecCategory.id];
        }
      } else {
        this.seletecClass = null;
        this.selectedClassList = [];
      }
      this.getList();
    },
    seletecClass() {
      this.getList();
    },
    editSeletecCategory(val) {
      if (this.editSeletecCategory) {
        this.editSeletecClass = null;
        if (val == "all") {
          this.editSelectedClassList = [];
        } else {
          this.editSelectedClassList =
            this.classNameList[this.editSeletecCategory.id];
        }
      } else {
        this.editSeletecClass = null;
        this.editSelectedClassList = [];
      }
    },
    "paging.perPage"(val) {
      this.parseList();
    },
  },
  methods: {
    sortList(key) {
      if (this.sortKey == key) {
        if (this.sortAsc == "asc") {
          this.sortAsc = "desc";
        } else {
          this.sortAsc = "asc";
        }
      } else {
        this.sortKey = key;
        this.sortAsc = "asc";
      }
      this.parseList();
    },
    getList() {
      const params = {};
      if (!this.seletecCategory || this.seletecCategory == "all") {
        params.category_id = null;
      } else {
        params.category_id = this.seletecCategory.id;
        if (!this.seletecClass || this.seletecClass == "all") {
          params.seletecClass = null;
        } else {
          params.class_id = this.seletecClass.id;
        }
      }

      EventBus.$emit("startLoading");
      post(apiUrl.getMusic, params, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          EventBus.$emit("endLoading");
          if (res.data.status == "success") {
            forEach(res.data.result, (o, i) => {
              o.num = i + 1;
              o.category_name = this.categoryNameList[o.category_id]
                ? this.categoryNameList[o.category_id][0].name
                : "";
              const targetClass = find(this.classList, { id: o.class_id });

              o.class_name = targetClass ? targetClass.name : "";
            });
            // this.originList = res.data.result.reverse();
            this.originList = res.data.result;
            this.paging.currentPage = 1;
            this.parseList();
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("endLoading");
        });
    },
    parseList(currentPage) {
      EventBus.$emit("startLoading");
      this.selectAll = false;
      this.paging.currentPage = currentPage ? currentPage : 1;
      const res = this.getCurrentList(
        this.searchText,
        this.sortKey,
        this.sortAsc,
        this.originList,
        this.paging.currentPage,
        this.paging.perPage
      );
      this.list = JSON.parse(JSON.stringify(res.list));
      this.paging.totalCnt = res.total;
      let info = this.pageAlgo(
        this.paging.totalCnt,
        this.paging.pageCount,
        this.paging.perPage,
        this.paging.currentPage
      );
      this.paging.totalPage = info.totalPageSize;
      this.paging.range = [];
      for (let i = info.firstBottomNumber; i <= info.lastBottomNumber; i++) {
        this.paging.range.push(i);
      }
      EventBus.$emit("endLoading");
    },
    async getCategoryList() {
      EventBus.$emit("startLoading");
      try {
        const res = await post(
          apiUrl.getCategory,
          {},
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        EventBus.$emit("endLoading");
        if (res.data.status == "success") {
          this.categoryList = res.data.result;
          this.categoryNameList = groupBy(this.categoryList, "id");
        } else {
          EventBus.$emit(
            "showToast",
            "실행중 실패했습니다. 다시 이용해 주세요."
          );
        }
      } catch (err) {
        EventBus.$emit("showToast", "실행중 실패했습니다. 다시 이용해 주세요.");
        EventBus.$emit("endLoading");
      }
    },
    getClassList() {
      EventBus.$emit("startLoading");
      post(
        apiUrl.getClass,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          EventBus.$emit("endLoading");
          if (res.data.status == "success") {
            this.classList = res.data.result;
            this.classNameList = groupBy(this.classList, "category_id");
            this.getList();
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("endLoading");
        });
    },
    editClick(obj) {
      this.selectedRow = JSON.parse(JSON.stringify(obj));
      this.editSeletecCategory = find(this.categoryList, {
        id: this.selectedRow.category_id,
      });

      setTimeout(() => {
        this.editSeletecClass = find(this.classList, {
          id: this.selectedRow.class_id,
        });
      }, 50);
    },

    update() {
      if (!confirm("수정하시겠습니까?")) {
        return;
      }
      const vm = this;

      if (!this.editSeletecCategory || !this.editSeletecClass) {
        EventBus.$emit("showToast", "항목을 모두 입력해 주세요.");
        return;
      }

      const params = {
        id: this.selectedRow.id,
        category_id: this.editSeletecCategory.id,
        class_id: this.editSeletecClass.id,
      };
      EventBus.$emit("startLoading");
      post(apiUrl.updateMusic, params, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          EventBus.$emit("endLoading");
          if (res.data.status == "success") {
            this.selectedRow = null;
            EventBus.$emit("showToast", "저장되었습니다.");
            this.getList();
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("endLoading");
        });
    },
    deleteRow() {
      if (!confirm("삭제하시겠습니까?")) {
        return;
      }

      const deleteArr = [];
      forEach(this.list, (o) => {
        if (o.ischecked) {
          deleteArr.push(o);
        }
      });

      if (!deleteArr.length) {
        return;
      }
      EventBus.$emit("startLoading");

      post(apiUrl.deleteMusic, deleteArr, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          EventBus.$emit("endLoading");
          if (res.data.status == "success") {
            EventBus.$emit("showToast", "삭제 되었습니다.");
            this.getList();
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("endLoading");
        });
    },
  },
};
</script>
<style lang="scss"></style>
