<template>
  <div class="content-box">
    <div class="location">배너관리 > 배너 관리</div>

    <div class="content-wrapper" v-if="selectedRow">
      <div class="tit-sub">배너 수정</div>
      <div class="input-df-row" style="margin: 10px 0">
        <input type="checkbox" v-model="selectedRow.isMainShowChk" />
        <span class="tit"> 메인노출 </span>
      </div>
      <br />
      <div class="input-df-row" style="margin: 10px 0">
        <span class="tit">배너명 : </span>
        <input type="text" v-model="selectedRow.name" />
      </div>
      <br />
      <div class="input-df-row" style="margin: 10px 0">
        <span class="tit">링크 (http를 포함한 모든 주소) : </span>
        <input
          type="text"
          v-model="selectedRow.link"
          placeholder=" 예) https://orff.kr/?page_id=57&uid=154&mod=document&pageid=1"
          style="width: 450px"
        />
      </div>
      <br />
      <div class="input-df-row" style="margin: 10px 0">
        <span class="tit">배너 이미지 : </span>
        <input type="file" id="attachment" name="attachment" />
      </div>
      <br />

      <button
        class="btn-blue"
        @click="edit"
        style="width: 300px; margin-top: 50px"
      >
        배 너 수 정
      </button>
      <button
        class="btn-white"
        @click="editCancel"
        style="width: 300px; margin-top: 50px; margin-left: 4%"
      >
        취 소
      </button>
    </div>

    <div class="list-df" v-show="!selectedRow">
      <div class="table-top">
        <div class="fl">
          표시 :
          <select v-model="paging.perPage">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <div class="fr">
          검색 :
          <input
            type="text"
            @input="
              searchText = $event.target.value;
              parseList();
            "
            placeholder="검색어를 입력해 주세요."
          />
        </div>
      </div>
      <table>
        <colgroup>
          <col width="70" />
          <col width="70" />
          <col width="250" />
          <col width="*" />
          <col width="200" />
          <col width="100" />
          <col width="220" />
          <col width="100" />
        </colgroup>
        <thead>
          <tr>
            <th
              class="sorting"
              @click="sortList('number')"
              v-bind:class="{
                sorting_asc: sortKey == 'number' && sortAsc == 'asc',
                sorting_desc: sortKey == 'number' && sortAsc == 'desc',
              }"
            >
              번호
            </th>
            <th>선택</th>
            <th
              class="sorting"
              @click="sortList('name')"
              v-bind:class="{
                sorting_asc: sortKey == 'name' && sortAsc == 'asc',
                sorting_desc: sortKey == 'name' && sortAsc == 'desc',
              }"
            >
              배너명
            </th>
            <th>링크</th>
            <th>이미지</th>
            <th
              class="sorting"
              @click="sortList('is_show_main')"
              v-bind:class="{
                sorting_asc: sortKey == 'is_show_main' && sortAsc == 'asc',
                sorting_desc: sortKey == 'is_show_main' && sortAsc == 'desc',
              }"
            >
              메인노출
            </th>
            <th
              class="sorting"
              @click="sortList('reg_date')"
              v-bind:class="{
                sorting_asc: sortKey == 'reg_date' && sortAsc == 'asc',
                sorting_desc: sortKey == 'reg_date' && sortAsc == 'desc',
              }"
            >
              등록일
            </th>
            <th>편집</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in list"
            v-bind:key="index"
            v-bind:class="{ active: selectedRow && selectedRow.id == row.id }"
          >
            <td>
              {{ row.num }}
            </td>
            <td>
              <input type="checkbox" v-model="row.ischecked" />
            </td>
            <td class="ta-left">
              {{ row.name }}
            </td>
            <td class="ta-left">
              <a v-bind:href="row.link" target="_blank">{{ row.link }}</a>
            </td>
            <td>
              <img
                v-bind:src="host + row.img_path"
                class="img-thumb"
                style="width: 150px; cursor: pointer"
                alt=""
                @click="ctxOpenImage(host + row.img_path)"
              />
            </td>
            <td>{{ row.is_show_main }}</td>
            <td class="ta-left">{{ convertDate(row.reg_date) }}</td>
            <td>
              <button class="btn-blue" @click="editClick(row)">편 집</button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination" v-if="list.length">
        <div class="btn-action">
          <button class="btn-orange" @click="deleteRow">선택삭제</button>
        </div>
        <div class="paging">
          <button
            v-bind:class="{ disabled: paging.currentPage == 1 }"
            @click="parseList(1)"
          >
            첫페이지
          </button>
          <button
            v-bind:class="{ disabled: paging.currentPage == 1 }"
            @click="parseList(paging.currentPage - 1)"
          >
            이전
          </button>
          <button
            v-for="index in paging.range"
            v-bind:key="index"
            @click="parseList(index)"
            v-bind:class="{ on: index == paging.currentPage }"
          >
            {{ index }}
          </button>
          <button
            v-bind:class="{ disabled: paging.currentPage == paging.totalPage }"
            @click="parseList(paging.currentPage + 1)"
          >
            다음
          </button>
          <button
            v-bind:class="{ disabled: paging.currentPage == paging.totalPage }"
            @click="parseList(paging.totalPage)"
          >
            끝페이지
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { post } from "axios";
import apiUrl from "@/apiUrl";
import EventBus from "@/eventbus/eventbus";
import { forEach, find, groupBy } from "lodash";
export default {
  components: {},
  data() {
    return {
      host: apiUrl.host,
      originList: null,
      list: [],
      searchText: "",
      sortKey: "",
      sortAsc: null,
      selectedRow: null,
      paging: {
        currentPage: 1,
        totalCnt: 0,
        perPage: 10,
        totalPage: 0,
        pageCount: 10,
        range: [],
      },
    };
  },
  created() {},
  mounted() {
    this.getList();
  },
  watch: {
    "paging.perPage"(val) {
      this.parseList();
    },
  },
  methods: {
    sortList(key) {
      if (this.sortKey == key) {
        if (this.sortAsc == "asc") {
          this.sortAsc = "desc";
        } else {
          this.sortAsc = "asc";
        }
      } else {
        this.sortKey = key;
        this.sortAsc = "asc";
      }
      this.parseList();
    },
    getList() {
      EventBus.$emit("startLoading");
      post(
        apiUrl.getBanners,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          EventBus.$emit("endLoading");
          if (res.data.status == "success") {
            forEach(res.data.result, (o, i) => {
              o.num = i + 1;
              o.isMainShowChk = o.is_show_main == "Y" ? true : false;
            });
            this.originList = res.data.result;
            this.paging.currentPage = 1;
            this.parseList();
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("endLoading");
        });
    },
    parseList(currentPage) {
      EventBus.$emit("startLoading");
      this.paging.currentPage = currentPage ? currentPage : 1;
      const res = this.getCurrentList(
        this.searchText,
        this.sortKey,
        this.sortAsc,
        this.originList,
        this.paging.currentPage,
        this.paging.perPage
      );
      this.list = JSON.parse(JSON.stringify(res.list));
      this.paging.totalCnt = res.total;
      let info = this.pageAlgo(
        this.paging.totalCnt,
        this.paging.pageCount,
        this.paging.perPage,
        this.paging.currentPage
      );
      this.paging.totalPage = info.totalPageSize;
      this.paging.range = [];
      for (let i = info.firstBottomNumber; i <= info.lastBottomNumber; i++) {
        this.paging.range.push(i);
      }
      EventBus.$emit("endLoading");
    },

    editCancel() {
      const attachment = document.querySelector("#attachment");
      attachment.value = null;
      this.selectedRow = null;
    },

    editClick(obj) {
      this.selectedRow = JSON.parse(JSON.stringify(obj));
    },
    edit() {
      if (!confirm("수정하시겠습니까?")) {
        return;
      }
      const vm = this;
      const formData = new FormData();
      const attachment = document.querySelector("#attachment");

      if (!this.selectedRow.name || this.selectedRow.name == "") {
        EventBus.$emit("showToast", "분류명을 입력해 주세요.");
        return;
      }

      formData.append("id", this.selectedRow.id);
      formData.append("name", this.selectedRow.name);
      formData.append("link", this.selectedRow.link);
      formData.append("attachment", attachment.files[0]);
      // 기존 섬네일을 삭제해야 할 경우 원본path
      if (attachment.files[0]) {
        formData.append("originPath", this.selectedRow.img_path);
      }
      formData.append(
        "is_show_main",
        this.selectedRow.isMainShowChk ? "Y" : "N"
      );
      EventBus.$emit("startLoading");
      post(apiUrl.updateBanner, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          EventBus.$emit("endLoading");
          if (res.data.status == "success") {
            this.editCancel();
            EventBus.$emit("showToast", "저장되었습니다.");
            this.getList();
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("endLoading");
        });
    },
    deleteRow() {
      if (!confirm("삭제하시겠습니까?")) {
        return;
      }

      const deleteArr = [];
      forEach(this.list, (o) => {
        if (o.ischecked) {
          deleteArr.push(o);
        }
      });

      if (!deleteArr.length) {
        return;
      }
      EventBus.$emit("startLoading");

      post(apiUrl.deleteBanners, deleteArr, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          EventBus.$emit("endLoading");
          if (res.data.status == "success") {
            EventBus.$emit("showToast", "삭제 되었습니다.");
            this.getList();
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("endLoading");
        });
    },
  },
};
</script>
<style lang="scss"></style>
