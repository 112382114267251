const isTestMode = false;
const host = isTestMode ? "http://localhost/" : "http://165.22.59.126/";
export default {
  host: host,
  login: host + "api/login",
  checkLogin: host + "api/checkLogin",
  splashImgUrl: host + "splashImg.png",
  changeSplashImg: host + "api/changeSplashImg",
  getCategory: host + "api/getCategory",
  addCategory: host + "api/addCategory",
  updateCategory: host + "api/updateCategory",
  deleteCategory: host + "api/deleteCategory",
  getClass: host + "api/getClass",
  addClass: host + "api/addClass",
  updateClass: host + "api/updateClass",
  deleteClass: host + "api/deleteClass",
  addMusic: host + "api/addMusic",
  getMusic: host + "api/getMusic",
  updateMusic: host + "api/updateMusic",
  deleteMusic: host + "api/deleteMusic",
  getRegion: host + "api/getRegion",
  addRegion: host + "api/addRegion",
  editRegion: host + "api/editRegion",
  deleteRegion: host + "api/deleteRegion",
  getBranch: host + "api/getBranch",
  addBranch: host + "api/addBranch",
  editBranch: host + "api/editBranch",
  deleteBranch: host + "api/deleteBranch",
  addTeacher: host + "api/addTeacher",
  getTeachers: host + "api/getTeachers",
  editTeacher: host + "api/editTeacher",
  deleteTeacher: host + "api/deleteTeacher",
  getBanners: host + "api/getBanners",
  addBanner: host + "api/addBanner",
  updateBanner: host + "api/updateBanner",
  deleteBanners: host + "api/deleteBanners",
  changeTestMode: host + "api/changeTestMode",
  getTestMode: host + "api/getTestMode",
};
