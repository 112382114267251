<template>
  <div class="content-box">
    <div class="location">음원관리 > 음원입력</div>

    <div class="content-wrapper">
      <div class="tit-sub">분류</div>
      <div class="input-df-row">
        <span class="tit">과목선택 : </span>
        <select v-model="seletecCategory">
          <option
            v-for="(item, index) in categoryList"
            :key="index"
            :value="item"
          >
            {{ item.name }}
          </option>
        </select>
      </div>
      <div class="input-df-row">
        <span class="tit">분류선택 : </span>
        <select v-model="seletecClass">
          <option
            v-for="(item, index) in selectedClassList"
            :key="index"
            :value="item"
          >
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="input-music-upload">
      파일선택 :

      <input
        type="file"
        id="attachment"
        name="attachment"
        accept=".mp3,audio/*"
        multiple
      />

      <button class="btn-blue w-80" @click="upload">음 원 입 력</button>
    </div>
  </div>
</template>
<script>
import { post } from "axios";
import apiUrl from "@/apiUrl";
import EventBus from "@/eventbus/eventbus";
import { forEach, find, groupBy } from "lodash";
export default {
  components: {},
  data() {
    return {
      categoryList: [],
      categoryNameList: {},
      seletecCategory: null,
      classList: [],
      selectedClassList: [],
      classNameList: {},
      seletecClass: null,
    };
  },
  created() {},
  async mounted() {
    await this.getCategoryList();
    this.getClassList();
  },
  watch: {
    seletecCategory(val) {
      if (this.seletecCategory) {
        this.seletecClass = null;
        this.selectedClassList = this.classNameList[this.seletecCategory.id];
      }
    },
  },
  methods: {
    async getCategoryList() {
      EventBus.$emit("startLoading");
      try {
        const res = await post(
          apiUrl.getCategory,
          {},
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        EventBus.$emit("endLoading");
        if (res.data.status == "success") {
          this.categoryList = res.data.result;
          this.categoryNameList = groupBy(this.categoryList, "id");
        } else {
          EventBus.$emit(
            "showToast",
            "실행중 실패했습니다. 다시 이용해 주세요."
          );
        }
      } catch (err) {
        EventBus.$emit("showToast", "실행중 실패했습니다. 다시 이용해 주세요.");
        EventBus.$emit("endLoading");
      }
    },
    getClassList() {
      EventBus.$emit("startLoading");
      post(
        apiUrl.getClass,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          EventBus.$emit("endLoading");
          if (res.data.status == "success") {
            this.classList = res.data.result;
            this.classNameList = groupBy(this.classList, "category_id");
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("endLoading");
        });
    },
    upload() {
      const vm = this;
      const formData = new FormData();
      const attachment = document.querySelector("#attachment");
      if (
        !attachment.files.length ||
        !this.seletecCategory ||
        !this.seletecClass
      ) {
        EventBus.$emit("showToast", "항목을 모두 입력해 주세요.");
        return;
      }
      forEach(attachment.files, (o) => {
        formData.append("attachment", o);
      });
      formData.append("category_id", this.seletecCategory.id);
      formData.append("class_id", this.seletecClass.id);

      if (!confirm("등록하시겠습니까?")) {
        return;
      }
      EventBus.$emit("startLoading");
      post(apiUrl.addMusic, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          EventBus.$emit("endLoading");
          if (res.data.status == "success") {
            EventBus.$emit("showToast", "저장되었습니다.");
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("endLoading");
        });
    },
  },
};
</script>
<style lang="scss"></style>
