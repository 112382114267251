<template>
  <div class="content-box">
    <div class="location">강사관리 > 강사목록</div>

    <div v-if="selectedRow">
      <div class="content-wrapper">
        <div class="tit-sub">강사 수정</div>
        <div class="input-df-row">
          <label
            v-bind:for="'category' + index + '-' + item.id"
            v-for="(item, index) in categoryList"
            :key="index"
            class="mgl-20"
          >
            <input
              type="checkbox"
              v-bind:id="'category' + index + '-' + item.id"
              v-model="item.isChecked"
            />
            {{ item.name }}
          </label>
        </div>
      </div>

      <div class="input-user">
        <table>
          <colgroup>
            <col width="20%" />
            <col width="*" />
          </colgroup>
          <tr>
            <th>이&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;름 :</th>
            <td>
              <input type="text" v-model="name" />
            </td>
          </tr>
          <tr>
            <th>지&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;사 :</th>
            <td>
              <select v-model="selectedRegion">
                <option
                  v-for="(item, index) in regionList"
                  :key="index"
                  :value="item"
                >
                  {{ item.name }}
                </option>
              </select>

              <select
                v-model="selectedBranch"
                v-if="selectedRegion"
                style="margin-left: 20px"
              >
                <option
                  v-for="(item, index) in selectedRegion.children"
                  :key="index"
                  :value="item"
                >
                  {{ item.name }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <th>전 화 번 호 :</th>
            <td>
              <input type="text" v-model="phoneNumber" />
            </td>
          </tr>
          <tr>
            <th>장 치 번 호 :</th>
            <td>
              <input type="text" v-model="deviceNumber" />
            </td>
          </tr>
        </table>
      </div>
      <button
        class="btn-blue"
        @click="edit"
        style="width: 23%; margin-top: 50px"
      >
        강 사 수 정
      </button>
      <button
        class="btn-white"
        @click="editCancel"
        style="width: 23%; margin-top: 50px; margin-left: 4%"
      >
        취 소
      </button>
    </div>

    <div class="list-df" v-else>
      <div class="table-top">
        <div class="fl">
          표시 :
          <select v-model="paging.perPage">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <div class="fr">
          검색 :
          <input
            type="text"
            @input="
              searchText = $event.target.value;
              parseList();
            "
            placeholder="검색어를 입력해 주세요."
          />
        </div>
      </div>
      <table>
        <colgroup>
          <col width="70" />
          <col width="150" />
          <col width="200" />
          <col width="*" />
          <col width="150" />
          <col width="250" />
          <col width="100" />
          <col width="100" />
        </colgroup>
        <thead>
          <tr>
            <th
              class="sorting"
              @click="sortList('num')"
              v-bind:class="{
                sorting_asc: sortKey == 'num' && sortAsc == 'asc',
                sorting_desc: sortKey == 'num' && sortAsc == 'desc',
              }"
            >
              번호
            </th>
            <th
              class="sorting"
              @click="sortList('name')"
              v-bind:class="{
                sorting_asc: sortKey == 'name' && sortAsc == 'asc',
                sorting_desc: sortKey == 'name' && sortAsc == 'desc',
              }"
            >
              이름
            </th>
            <th
              class="sorting"
              @click="sortList('branch_name')"
              v-bind:class="{
                sorting_asc: sortKey == 'branch_name' && sortAsc == 'asc',
                sorting_desc: sortKey == 'branch_name' && sortAsc == 'desc',
              }"
            >
              지사
            </th>
            <th
              class="sorting"
              @click="sortList('access_names')"
              v-bind:class="{
                sorting_asc: sortKey == 'access_names' && sortAsc == 'asc',
                sorting_desc: sortKey == 'access_names' && sortAsc == 'desc',
              }"
            >
              권한
            </th>
            <th
              class="sorting"
              @click="sortList('phone_number')"
              v-bind:class="{
                sorting_asc: sortKey == 'phone_number' && sortAsc == 'asc',
                sorting_desc: sortKey == 'phone_number' && sortAsc == 'desc',
              }"
            >
              전화번호
            </th>
            <th
              class="sorting"
              @click="sortList('device_number')"
              v-bind:class="{
                sorting_asc: sortKey == 'device_number' && sortAsc == 'asc',
                sorting_desc: sortKey == 'device_number' && sortAsc == 'desc',
              }"
            >
              장치번호
            </th>
            <th>편집</th>
            <th>삭제</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in list"
            v-bind:key="index"
            v-bind:class="{ active: selectedRow && selectedRow.id == row.id }"
          >
            <td>{{ row.num }}</td>
            <td class="ta-left">{{ row.name }}</td>
            <td class="ta-left">{{ row.branch_name }}</td>
            <td class="ta-left">{{ row.access_names }}</td>
            <td class="ta-left">{{ row.phone_number }}</td>
            <td class="ta-left">{{ row.device_number }}</td>
            <td class="ta-left">
              <button class="btn-blue" @click="editClick(row)">편 집</button>
            </td>
            <td class="ta-left">
              <button class="btn-orange h-24" @click="deleteTeacher(row)">
                삭 제
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination" v-if="list.length">
        <div class="paging">
          <button
            v-bind:class="{ disabled: paging.currentPage == 1 }"
            @click="parseList(1)"
          >
            첫페이지
          </button>
          <button
            v-bind:class="{ disabled: paging.currentPage == 1 }"
            @click="parseList(paging.currentPage - 1)"
          >
            이전
          </button>
          <button
            v-for="index in paging.range"
            v-bind:key="index"
            @click="parseList(index)"
            v-bind:class="{ on: index == paging.currentPage }"
          >
            {{ index }}
          </button>
          <button
            v-bind:class="{ disabled: paging.currentPage == paging.totalPage }"
            @click="parseList(paging.currentPage + 1)"
          >
            다음
          </button>
          <button
            v-bind:class="{ disabled: paging.currentPage == paging.totalPage }"
            @click="parseList(paging.totalPage)"
          >
            끝페이지
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { post } from "axios";
import apiUrl from "@/apiUrl";
import EventBus from "@/eventbus/eventbus";
import { forEach, find, groupBy } from "lodash";
export default {
  components: {},
  data() {
    return {
      name: null,
      phoneNumber: null,
      deviceNumber: null,
      selectedRegion: null,
      selectedBranch: null,

      originList: null,
      searchText: "",
      sortKey: "",
      sortAsc: null,
      selectedRow: null,
      regionList: [],
      branchList: [],
      regionNameList: {},
      branchNameList: {},
      categoryList: [],
      categoryNameList: {},
      list: [],
      paging: {
        currentPage: 1,
        totalCnt: 0,
        perPage: 10,
        totalPage: 0,
        pageCount: 10,
        range: [],
      },
    };
  },
  created() {},
  mounted() {
    this.getCategoryList();
  },
  watch: {
    "paging.perPage"(val) {
      this.parseList();
    },
  },
  methods: {
    sortList(key) {
      if (this.sortKey == key) {
        if (this.sortAsc == "asc") {
          this.sortAsc = "desc";
        } else {
          this.sortAsc = "asc";
        }
      } else {
        this.sortKey = key;
        this.sortAsc = "asc";
      }
      this.parseList();
    },
    async getCategoryList() {
      EventBus.$emit("startLoading");
      try {
        const res = await post(
          apiUrl.getCategory,
          {},
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (res.data.status == "success") {
          this.categoryNameList = {};
          forEach(res.data.result, (o) => {
            this.categoryNameList[o.id] = o.name;
          });
          this.categoryList = res.data.result;
          this.getRegionList();
        } else {
          EventBus.$emit(
            "showToast",
            "실행중 실패했습니다. 다시 이용해 주세요."
          );
        }
      } catch (err) {
        EventBus.$emit("showToast", "실행중 실패했습니다. 다시 이용해 주세요.");
        EventBus.$emit("endLoading");
      }
    },
    getRegionList() {
      EventBus.$emit("startLoading");
      post(
        apiUrl.getRegion,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.data.status == "success") {
            this.regionList = res.data.result;
            this.regionObjList = {};
            forEach(this.regionList, (o, k) => {
              o["children"] = [];
              this.regionNameList[o.id] = o.name;
            });
            this.getBranchList();
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBranchList() {
      post(
        apiUrl.getBranch,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.data.status == "success") {
            const vm = this;
            vm.branchList = res.data.result;
            const tempObj = JSON.parse(JSON.stringify(this.regionList));
            forEach(vm.branchList, (o) => {
              const targetRegion = find(tempObj, { id: o.region_id });
              targetRegion["children"].push(o);
              vm.branchNameList[o.id] = o.name;
            });
            this.regionList = [];
            forEach(tempObj, (o) => {
              if (o.children.length) {
                vm.regionList.push(o);
              }
            });
            // this.regionList = tempObj;
            this.selectedRegion = this.regionList[0];
            this.selectedBranch = this.regionList[0].children[0];
            this.getList();
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
            EventBus.$emit("endLoading");
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("endLoading");
        });
    },
    getList() {
      EventBus.$emit("startLoading");
      post(
        apiUrl.getTeachers,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          EventBus.$emit("endLoading");
          if (res.data.status == "success") {
            const vm = this;
            forEach(res.data.result, (o, i) => {
              o.num = i + 1;
              o.branch_name = `${vm.regionNameList[o.region_id]} / ${
                vm.branchNameList[o.branch_id]
              }`;
              o.access_ids = JSON.parse(o.access_ids);
              o.access_names = [];
              forEach(o.access_ids, (oo) => {
                if (this.categoryNameList[oo]) {
                  o.access_names.push(this.categoryNameList[oo]);
                }
              });
              o.access_names = o.access_names.join(", ");
            });
            // this.originList = res.data.result.reverse();
            this.originList = res.data.result;
            this.paging.currentPage = 1;
            this.parseList();
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("endLoading");
        });
    },
    parseList(currentPage) {
      EventBus.$emit("startLoading");
      this.paging.currentPage = currentPage ? currentPage : 1;
      const res = this.getCurrentList(
        this.searchText,
        this.sortKey,
        this.sortAsc,
        this.originList,
        this.paging.currentPage,
        this.paging.perPage
      );
      this.list = JSON.parse(JSON.stringify(res.list));
      this.paging.totalCnt = res.total;
      let info = this.pageAlgo(
        this.paging.totalCnt,
        this.paging.pageCount,
        this.paging.perPage,
        this.paging.currentPage
      );
      this.paging.totalPage = info.totalPageSize;
      this.paging.range = [];
      for (let i = info.firstBottomNumber; i <= info.lastBottomNumber; i++) {
        this.paging.range.push(i);
      }
      EventBus.$emit("endLoading");
    },
    editClick(obj) {
      this.selectedRow = JSON.parse(JSON.stringify(obj));

      this.name = this.selectedRow.name;
      this.phoneNumber = this.selectedRow.phone_number;
      this.deviceNumber = this.selectedRow.device_number;

      forEach(this.categoryList, (o) => {
        o.isChecked = false;
        forEach(this.selectedRow.access_ids, (oo) => {
          if (oo == o.id) {
            o.isChecked = true;
          }
        });
      });

      this.selectedRegion = find(this.regionList, {
        id: this.selectedRow.region_id,
      });
      this.selectedBranch = find(this.selectedRegion.children, {
        id: this.selectedRow.branch_id,
      });
    },
    editCancel() {
      forEach(this.categoryList, (o) => {
        o.isChecked = false;
      });
      this.name = null;
      this.selectedRegion = null;
      this.selectedBranch = null;
      this.selectedRow = null;
    },

    edit() {
      if (!this.name || this.name == null) {
        EventBus.$emit("showToast", "이름을 입력 하세요.");
        return;
      }
      if (!this.phoneNumber || this.phoneNumber == null) {
        EventBus.$emit("showToast", "전화번호를 입력 하세요.");
        return;
      }
      if (!confirm("수정하시겠습니까?")) {
        return;
      }

      const checkedCategory = [];

      forEach(this.categoryList, (o) => {
        if (o.isChecked) {
          checkedCategory.push(o.id);
        }
      });

      EventBus.$emit("startLoading");
      post(
        apiUrl.editTeacher,
        {
          id: this.selectedRow.id,
          name: this.name,
          region_id: this.selectedRegion.id,
          branch_id: this.selectedBranch.id,
          access_ids: JSON.stringify(checkedCategory),
          phone_number: this.phoneNumber,
          device_number:
            this.deviceNumber && this.deviceNumber != ""
              ? this.deviceNumber
              : null,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.data.status == "success") {
            EventBus.$emit("showToast", "수정 되었습니다.");
            this.editCancel();
            this.getList();
            EventBus.$emit("endLoading");
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
            EventBus.$emit("endLoading");
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("endLoading");
        });
    },

    deleteTeacher(obj) {
      if (!confirm("삭제 하시겠습니까?")) {
        return;
      }
      EventBus.$emit("startLoading");

      post(
        apiUrl.deleteTeacher,
        { id: obj.id },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          EventBus.$emit("endLoading");
          if (res.data.status == "success") {
            EventBus.$emit("showToast", "삭제 되었습니다.");
            this.getList();
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("endLoading");
        });
    },

    /// 강사 편집 부터
  },
};
</script>
<style lang="scss"></style>
