<template>
  <div
    id="toastTop"
    class="position-absolute fixed-top fill-vw text-center"
    role="alert"
    v-bind:class="{ on: !isHide }"
  >
    <!-- HOW-TO toggle : $(..).toggleClass('end') -->
    <span
      class="message position-absolute fill-vw alert alert-border"
      v-bind:class="{ end: isHide }"
      >{{ msg }}</span
    >
  </div>
</template>
<script>
import EventBus from "@/eventbus/eventbus";
export default {
  data() {
    return {
      msg: "",
      isHide: true,
      showToastTO: null,
    };
  },
  components: {},
  created: function () {
    EventBus.$on("showToast", this.showToast);
  },
  methods: {
    showToast(msg) {
      const vm = this;
      vm.msg = msg;
      vm.isHide = false;
      if (vm.showToastTO) {
        clearTimeout(vm.showToastTO);
        vm.showToastTO = null;
      }
      vm.showToastTO = setTimeout(() => {
        vm.isHide = true;
      }, 1000);
    },
  },
  beforeDestroy() {
    if (this.showToastTO) {
      clearTimeout(this.showToastTO);
      this.showToastTO = null;
    }
    EventBus.$off("showToast");
  },
};
</script>
<style scoped></style>
