import Vue from "vue";
import App from "./App.vue"; // Dashboard

import GlobalComponents from "./plugins/globalComponents";
import globalFnc from "./plugins/globalFnc";

// router setup
import router from "./routes/router";

// vuex setup
import { store } from "./store/store";

import axios from "axios";
function VueInit() {
  Vue.use(GlobalComponents);
  Vue.use(globalFnc);
  Vue.config.productionTip = false;

  new Vue({
    el: "#app",
    router,
    store,
    render: (h) => h(App),
  });
}
VueInit();
