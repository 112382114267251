<template>
  <div id="dashboardContainer">
    <div id="topArea">뮤앤무 관리자 페이지</div>
    <div id="middleWrapper">
      <left-menu></left-menu>
      <div id="mainContent">
        <router-view></router-view>
      </div>
    </div>
    <top-toast-message></top-toast-message>
    <spinner></spinner>
  </div>
</template>
<script>
import TopToastMessage from "@/components/comm/TopToastMessage.vue";
import LeftMenu from "./LeftMenu.vue";
import Spinner from "@/components/comm/Spinner.vue";
import { post } from "axios";
import apiUrl from "@/apiUrl";
import EventBus from "@/eventbus/eventbus";
export default {
  components: { LeftMenu, TopToastMessage, Spinner },
  data() {
    return {};
  },
  created() {
    this.checkLogin();
  },
  mounted() {},
  methods: {
    async checkLogin() {
      post(
        apiUrl.checkLogin,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.data.status == "success") {
            if (res.data.result == false) {
              EventBus.$emit("showToast", "사용자 세션이 만료 되었습니다.");
              this.$router.push("/");
            }
          } else {
            EventBus.$emit("showToast", "사용자 세션이 만료 되었습니다.");
            this.$router.push("/");
          }
        })
        .catch((err) => {
          EventBus.$emit(
            "showToast",
            "실행중 실패했습니다. 다시 이용해 주세요."
          );
          EventBus.$emit("endLoading");
        });
    },
  },
};
</script>
<style lang="scss">
/* @import "../../assets/scss/style.scss"; */
</style>
