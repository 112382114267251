/*
전역 사용 컴포넌트 등록
*/

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {},
};

export default GlobalComponents;
