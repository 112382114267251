<template>
  <div class="content-box">
    <div class="location">앱 시작화면 관리 > 앱 시작화면 관리</div>

    <div class="content-wrapper" v-if="!isEditMode">
      <div class="tit-sub">
        현재 이미지 :
        <span v-if="!originImg">없음</span>

        <button class="btn-blue" @click="isEditMode = true">변 경</button>
      </div>
      <div v-if="originImg" style="margin-top: 10px">
        <img
          v-bind:src="originImg"
          style="max-width: 500px; vertical-align: top"
          alt=""
        />
      </div>
    </div>

    <div class="content-wrapper" v-else>
      <div class="tit-sub">이미지 수정</div>
      <div class="input-df-row">
        <span class="tit">이미지 입력: </span>
        <input type="file" id="attachment" name="attachment" accept=".png" />
        <button class="btn-blue" @click="edit">수 정</button>
        <button
          class="btn-white"
          @click="isEditMode = false"
          style="margin-left: 10px"
        >
          취 소
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { post } from "axios";
import apiUrl from "@/apiUrl";
import EventBus from "@/eventbus/eventbus";
import { forEach, find, groupBy } from "lodash";
export default {
  components: {},
  data() {
    return {
      originImg: null,
      isEditMode: false,
    };
  },
  created() {},
  mounted() {
    this.isImgCheck();
  },
  methods: {
    isImgCheck() {
      const img = new Image();

      img.onload = () => {
        this.originImg = apiUrl.splashImgUrl;
      };
      img.onerror = () => {
        this.originImg = null;
      };

      img.src = apiUrl.splashImgUrl;
    },
    edit() {
      if (!confirm("수정하시겠습니까?")) {
        return;
      }
      const formData = new FormData();
      const attachment = document.querySelector("#attachment");

      if (!attachment.files.length) {
        EventBus.$emit("showToast", "이미지를 선택 해 주세요.");
      }
      this.originImg = null;
      formData.append("attachment", attachment.files[0]);
      EventBus.$emit("startLoading");
      post(apiUrl.changeSplashImg, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          EventBus.$emit("endLoading");
          if (res.data.status == "success") {
            this.$forceUpdate();
            this.editCancel();
            location.reload();
            EventBus.$emit("showToast", "저장되었습니다.");
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("endLoading");
        });
    },
    editCancel() {
      const attachment = document.querySelector("#attachment");
      attachment.value = null;
      this.isEditMode = false;
    },
  },
};
</script>
<style lang="scss"></style>
