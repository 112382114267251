<template>
  <div id="loginWrapper">
    <div class="login-box">
      <div class="title">관리자정보를 입력하세요.</div>
      <div class="contents-box">
        <input type="text" placeholder="관리자 아이디" v-model="userName" />
        <input type="password" placeholder="비밀번호" v-model="userPw" />
        <button @click="login">로그인</button>
        <div class="error-msg">{{ errorMsg }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { post } from "axios";
import apiUrl from "@/apiUrl";
import EventBus from "@/eventbus/eventbus";
export default {
  components: {},
  data() {
    return {
      userName: null,
      userPw: null,
      errorMsg: null,
    };
  },
  created() {
    this.checkLogin();
  },
  watch: {},
  mounted() {},
  methods: {
    async login() {
      if (
        !this.userName ||
        this.userName == "" ||
        !this.userPw ||
        this.userPw == ""
      ) {
        this.errorMsg = "항목을 입력해 주세요.";
        return;
      }
      this.errorMsg = null;
      EventBus.$emit("startLoading");
      post(
        apiUrl.login,
        { name: this.userName, password: this.userPw },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.data.status == "success") {
            this.errorMsg = null;
            EventBus.$emit("endLoading");
            this.$router.push("/home");
          } else {
            this.errorMsg = res.data.result;
          }
        })
        .catch((err) => {
          EventBus.$emit(
            "showToast",
            "실행중 실패했습니다. 다시 이용해 주세요."
          );
          EventBus.$emit("endLoading");
        });
    },
    async checkLogin() {
      post(
        apiUrl.checkLogin,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.data.status == "success") {
            if (res.data.result == true) {
              this.$router.push("/home");
            }
          }
        })
        .catch((err) => {
          EventBus.$emit(
            "showToast",
            "실행중 실패했습니다. 다시 이용해 주세요."
          );
          EventBus.$emit("endLoading");
        });
    },
  },
};
</script>
<style lang="scss">
/* @import "../../assets/scss/style.scss"; */
body {
  background: #f8f8f8;
}
#loginWrapper {
  background: #f8f8f8;
  .login-box {
    position: absolute;
    top: 20%;
    left: 50%;
    width: 360px;
    margin: 0 0 0 -180px;
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    border-color: #ddd;
    .title {
      display: block;
      padding: 10px 15px;
      border-bottom: 1px solid transparent;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      color: #333;
      background-color: #f5f5f5;
      border-color: #ddd;
      margin-top: 0;
      margin-bottom: 0;
      font-size: 16px;
      color: inherit;
    }
    .contents-box {
      box-sizing: border-box;
      padding: 15px;
      input {
        box-sizing: border-box;
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        -webkit-transition: border-color ease-in-out 0.15s,
          -webkit-box-shadow ease-in-out 0.15s;
        -o-transition: border-color ease-in-out 0.15s,
          box-shadow ease-in-out 0.15s;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        margin-bottom: 15px;
      }
      button {
        display: block;
        padding: 10px 16px;
        font-size: 18px;
        line-height: 1.3333333;
        border-radius: 6px;
        color: #fff;
        background-color: #286090;
        border: 1px solid transparent;
        border-color: #204d74;
        width: 100%;
        cursor: pointer;
      }
      .error-msg {
        padding: 10px 0 0 0;
        color: #ff0000;
      }
    }
  }
}
</style>
