<template>
  <div class="content-box">
    <div class="location">강사관리 > 강사등록</div>
    <div class="content-wrapper">
      <div class="input-df-row">
        <label
          for="checkedAll"
          style="display: inline-block; margin-right: 15px"
        >
          <input type="checkbox" id="checkedAll" v-model="isCheckedAll" />
          <b>전체</b>
        </label>
        <label
          v-bind:for="'category' + index + '-' + item.id"
          v-for="(item, index) in categoryList"
          :key="index"
          class="mgl-20"
        >
          <input
            type="checkbox"
            v-bind:id="'category' + index + '-' + item.id"
            v-model="item.isChecked"
          />
          {{ item.name }}
        </label>
      </div>
    </div>

    <div class="input-user">
      <table>
        <colgroup>
          <col width="20%" />
          <col width="*" />
        </colgroup>
        <tr>
          <th>이&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;름 :</th>
          <td>
            <input type="text" v-model="name" />
          </td>
        </tr>
        <tr>
          <th>지&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;사 :</th>
          <td>
            <select v-model="selectedRegion">
              <option
                v-for="(item, index) in regionList"
                :key="index"
                :value="item"
              >
                {{ item.name }}
              </option>
            </select>

            <select
              v-model="selectedBranch"
              v-if="selectedRegion"
              style="margin-left: 20px"
            >
              <option
                v-for="(item, index) in selectedRegion.children"
                :key="index"
                :value="item"
              >
                {{ item.name }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <th>전 화 번 호 :</th>
          <td>
            <input type="text" v-model="phoneNumber" />
          </td>
        </tr>
        <tr>
          <th>장 치 번 호 :</th>
          <td>
            <input type="text" v-model="deviceNumber" />
          </td>
        </tr>
      </table>
    </div>
    <button
      class="btn-blue"
      @click="upload"
      style="width: 50%; margin-top: 50px"
    >
      강 사 등 록
    </button>
  </div>
</template>
<script>
import { post } from "axios";
import apiUrl from "@/apiUrl";
import EventBus from "@/eventbus/eventbus";
import { forEach, find, groupBy } from "lodash";
export default {
  components: {},
  data() {
    return {
      name: null,
      phoneNumber: null,
      deviceNumber: null,
      categoryList: [],
      selectedRegion: null,
      regionList: [],
      branchList: [],
      selectedBranch: null,
      isCheckedAll: false,
    };
  },
  created() {},
  async mounted() {
    await this.getCategoryList();
    this.getRegionList();
  },
  watch: {
    isCheckedAll(value) {
      forEach(this.categoryList, (o) => {
        o.isChecked = value;
      });
    },
  },
  methods: {
    async getCategoryList() {
      EventBus.$emit("startLoading");
      try {
        const res = await post(
          apiUrl.getCategory,
          {},
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        EventBus.$emit("endLoading");
        if (res.data.status == "success") {
          this.categoryList = res.data.result;
        } else {
          EventBus.$emit(
            "showToast",
            "실행중 실패했습니다. 다시 이용해 주세요."
          );
        }
      } catch (err) {
        EventBus.$emit("showToast", "실행중 실패했습니다. 다시 이용해 주세요.");
        EventBus.$emit("endLoading");
      }
    },
    getRegionList() {
      EventBus.$emit("startLoading");
      post(
        apiUrl.getRegion,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.data.status == "success") {
            this.regionList = res.data.result;
            forEach(this.regionList, (o) => {
              o["children"] = [];
            });
            this.getBranchList();
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBranchList() {
      post(
        apiUrl.getBranch,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.data.status == "success") {
            const vm = this;
            vm.branchList = res.data.result;
            const tempObj = JSON.parse(JSON.stringify(this.regionList));
            forEach(vm.branchList, (o) => {
              const targetRegion = find(tempObj, { id: o.region_id });
              targetRegion["children"].push(o);
            });
            this.regionList = [];
            forEach(tempObj, (o) => {
              if (o.children.length) {
                vm.regionList.push(o);
              }
            });
            // this.regionList = tempObj;
            this.selectedRegion = this.regionList[0];
            this.selectedBranch = this.regionList[0].children[0];
            EventBus.$emit("endLoading");
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
            EventBus.$emit("endLoading");
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("endLoading");
        });
    },
    upload() {
      if (!this.name || this.name == null) {
        EventBus.$emit("showToast", "이름을 입력 하세요.");
        return;
      }
      if (!this.phoneNumber || this.phoneNumber == null) {
        EventBus.$emit("showToast", "전화번호를 입력 하세요.");
        return;
      }

      const checkedCategory = [];

      forEach(this.categoryList, (o) => {
        if (o.isChecked) {
          checkedCategory.push(o.id);
        }
      });
      EventBus.$emit("startLoading");
      post(
        apiUrl.addTeacher,
        {
          name: this.name,
          region_id: this.selectedRegion.id,
          branch_id: this.selectedBranch.id,
          access_ids: JSON.stringify(checkedCategory),
          phone_number: this.phoneNumber,
          device_number:
            this.deviceNumber && this.deviceNumber != ""
              ? this.deviceNumber
              : null,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.data.status == "success") {
            EventBus.$emit("showToast", "추가 되었습니다.");
            this.name = null;
            forEach(this.categoryList, (o) => {
              o.isChecked = false;
            });
            this.phoneNumber = null;
            this.deviceNumber = null;
            EventBus.$emit("endLoading");
          } else {
            EventBus.$emit(
              "showToast",
              "실행중 실패했습니다. 다시 이용해 주세요."
            );
            EventBus.$emit("endLoading");
          }
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("endLoading");
        });
    },
  },
};
</script>
<style lang="scss"></style>
