import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import MusicUpload from "@/components/menu/MusicUpload";
import MusicList from "@/components/menu/MusicList";
import TeacherAdd from "@/components/menu/TeacherAdd";
import TeacherList from "@/components/menu/TeacherList";
import BranchAdd from "@/components/menu/BranchAdd";
import CategoryAddDelete from "@/components/menu/CategoryAddDelete";
import ClassAddDelete from "@/components/menu/ClassAddDelete";
import PlaylistAdd from "@/components/menu/PlaylistAdd";
import PlaylistManage from "@/components/menu/PlaylistManage";
import BannerAdd from "@/components/menu/BannerAdd";
import BannerManage from "@/components/menu/BannerManage";
import SplashImage from "@/components/menu/SplashImage";
import TestModeSet from "@/components/menu/TestModeSet";
import LoginLayout from "@/views/Layout/LoginLayout";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    component: LoginLayout,
  },
  {
    path: "/home",
    component: DashboardLayout,
    children: [
      {
        path: "musicupload",
        name: "musicupload",
        component: MusicUpload,
      },
      {
        path: "",
        name: "musiclist",
        component: MusicList,
      },
      {
        path: "teacheradd",
        name: "teacheradd",
        component: TeacherAdd,
      },
      {
        path: "teacherlist",
        name: "teacherlist",
        component: TeacherList,
      },
      {
        path: "branchadd",
        name: "branchadd",
        component: BranchAdd,
      },
      {
        path: "categoryadddelete",
        name: "categoryadddelete",
        component: CategoryAddDelete,
      },
      {
        path: "classadddelete",
        name: "classadddelete",
        component: ClassAddDelete,
      },
      {
        path: "playlistadd",
        name: "playlistadd",
        component: PlaylistAdd,
      },
      {
        path: "playlistmanage",
        name: "playlistmanage",
        component: PlaylistManage,
      },
      {
        path: "banneradd",
        name: "banneradd",
        component: BannerAdd,
      },
      {
        path: "bannermanage",
        name: "bannermanage",
        component: BannerManage,
      },
      {
        path: "splashimage",
        name: "splashimage",
        component: SplashImage,
      },
      {
        path: "testmodeset",
        name: "testmodeset",
        component: TestModeSet,
      },
    ],
  },
];

export default routes;
