<template>
  <div class="content-box">
    <div class="location">추천 플레이리스트 관리 > 추천 플레이리스트 추가</div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss"></style>
